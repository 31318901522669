@media only screen and (max-width: 600px) and (min-width: 300px){
    .Carousel{
        display: block !important;
        
    }
    .control-arrow{
        height: 70px !important;
    }
}
/* .corouselImg{
height: 380px !important;

} */
.control-dots{
    display: none !important;
}


.carousel.carousel-slider .control-arrow {
    height: 230px !important;
    justify-content: center !important;
    top:100 !important;
}
.carousel * {
    box-sizing: border-box;
    height: 280px;
}